import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

import de from "vuetify/src/locale/de";
import { getLocale } from "@/i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#de735f",
        secondary: "#3d4059",
        text: "#f2eddc",
        accent: "#eeb06e",
        background: "#ededed",
        gray: "#808080",
      },
      dark: {
        primary: "#de735f",
        secondary: "#3d4059",
        accent: "#eeb06e",
        background: "#121212",
        gray: "#808080",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { de },
    current: getLocale(),
  },
});
