import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
const TablesView = () => import("@/views/admin/TablesView.vue");
const StatisticsView = () => import("@/views/admin/StatisticsView.vue");
const SettingsView = () => import("@/views/admin/SettingsView.vue");
const AdminView = () => import("@/views/admin/AdminView.vue");
import { WindowMode } from "@/lib/window_mode";
const MenuTypesView = () => import("@/views/admin/menus/MenuTypesView.vue");
const MenuEditView = () => import("@/views/admin/MenuEditView.vue");
const SignUpView = () => import("@/views/auth/SignUpView.vue");
const LoginView = () => import("@/views/auth/LoginView.vue");
const GeneralSettingsView = () =>
  import("@/views/admin/settings/GeneralSettingsView.vue");
const OrderSettingsView = () =>
  import("@/views/admin/settings/OrderSettingsView.vue");
const OrderView = () => import("@/views/client/OrderView.vue");
const OrderProcessingView = () =>
  import("@/views/admin/OrderProcessingView.vue");
const TablesPrintView = () => import("@/views/admin/TablesPrintView.vue");
const AccountSettingsView = () =>
  import("@/views/admin/settings/AccountSettingsView.vue");
const HostsSettingsView = () =>
  import("@/views/admin/settings/HostsSettingsView.vue");
const MenusView = () => import("@/views/admin/MenusView.vue");
import { MenuContentType } from "@common/components/menu";
const OrderMainView = () => import("@/views/client/OrderMainView.vue");
const OrderMenusView = () => import("@/views/client/OrderMenusView.vue");
const OrderMenuItemsView = () =>
  import("@/views/client/OrderMenuItemsView.vue");
const HostCreationView = () =>
  import("@/views/admin/host/create/HostCreationView.vue");
const ResetPasswordView = () => import("@/views/auth/ResetPasswordView.vue");
import { getSession } from "@/lib/auth/auth";
import { getApplicationMode } from "@/lib/application-mode";
const WebMainView = () => import("@/views/web/WebMainView.vue");
const ImprintView = () => import("@/views/web/ImprintView.vue");
const PrivacyView = () => import("@/views/web/PrivacyView.vue");
const StaffOrderView = () => import("@/views/admin/staff/StaffOrderView.vue");
const StaffMobileOrderView = () =>
  import("@/views/admin/staff/StaffMobileOrderView.vue");

Vue.use(VueRouter);

/**
 * The predefined names for the routes.
 */
export const routeNames = {
  root: "root",
  imprint: "imprint",
  privacy: "privacy",
  login: "login",
  signup: "signup",
  "reset-password": "reset-password",
  admin: "admin",
  "admin-host": "admin-host",
  "order-processing": "order-processing",
  menus: "menus",
  "menus-type": "menus-",
  "menu-edit": "menu-edit",
  tables: "tables",
  "print-tables": "print-tables",
  "print-table": "print-table",
  statistics: "statistics",
  settings: "settings",
  "settings-general": "settings-general",
  "settings-order": "settings-order",
  "settings-account": "settings-account",
  "settings-hosts": "settings-hosts",
  order: "order",
  "order-main": "order-main",
  "order-menu-type": "order-menu-type-",
  "order-menu-items": "order-menu-items",
  "order-table": "order-table",
  "staff-ordering": "staff-ordering",
  "staff-mobile-ordering": "staff-mobile-ordering",
};

/**
 * Predefined redirects.
 */
export const redirects = {
  admin: routeNames["order-processing"],
};

/**
 * Redirects to `admin` if the current session is not null.
 *
 * @param to the route the user navigates to.
 * @param from the route the user navigates from.
 * @param next a callback function which optionally accepts a new route.
 */
async function beforeEnterAuth(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) {
  try {
    await getSession();
    // Session is not null.
    next({ name: routeNames.admin });
  } catch (error) {
    // Session is null.
    next();
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: routeNames.root,
    component: WebMainView,
  },
  {
    path: "/imprint",
    name: routeNames.imprint,
    component: ImprintView,
  },
  {
    path: "/privacy",
    name: routeNames.privacy,
    component: PrivacyView,
  },
  // Login / Signup
  {
    path: "/login",
    name: routeNames.login,
    component: LoginView,
    beforeEnter: beforeEnterAuth,
  },
  {
    path: "/signup",
    name: routeNames.signup,
    component: SignUpView,
    beforeEnter: beforeEnterAuth,
  },
  {
    path: "/reset-password",
    name: routeNames["reset-password"],
    component: ResetPasswordView,
    beforeEnter: beforeEnterAuth,
  },
  // Host Admin
  {
    path: "/admin",
    name: routeNames.admin,
    component: AdminView,
    redirect: {
      name: redirects["admin"],
    },
    beforeEnter: async (to, from, next) => {
      try {
        await getSession();
        // Session is not null.
        next();
      } catch (error) {
        // Session is null.
        next({ name: routeNames.login });
      }
    },
    props: (route) => {
      return {
        changePassword: route.query["change-password"] !== undefined,
        applicationMode: getApplicationMode(),
      };
    },
    children: [
      {
        path: "host",
        name: routeNames["admin-host"],
        component: HostCreationView,
      },
      {
        path: "order",
        name: routeNames["order-processing"],
        component: OrderProcessingView,
        meta: {
          staff: true,
        },
      },
      {
        path: "staff-ordering",
        name: routeNames["staff-ordering"],
        component: StaffOrderView,
        props: () => ({
          applicationMode: getApplicationMode(),
        }),
        meta: {
          staff: true,
        },
      },
      {
        path: "staff-mobile-ordering",
        name: routeNames["staff-mobile-ordering"],
        component: StaffMobileOrderView,
        props: () => ({
          applicationMode: getApplicationMode(),
        }),
        meta: {
          staff: true,
        },
      },
      {
        path: "menus",
        name: routeNames.menus,
        component: MenusView,
        redirect: {
          name: routeNames["menus-type"] + Object.values(MenuContentType)[0],
        },
        children: Object.values(MenuContentType).map(
          (type: string): RouteConfig => {
            return {
              path: type,
              name: routeNames["menus-type"] + type,
              props: { type: type },
              component: MenuTypesView,
            };
          }
        ),
      },
      {
        path: "menus/:menuId",
        name: routeNames["menu-edit"],
        component: MenuEditView,
      },
      {
        path: "tables",
        name: routeNames.tables,
        component: TablesView,
      },
      {
        path: "tables/print",
        name: routeNames["print-tables"],
        component: TablesPrintView,
        meta: {
          windowMode: WindowMode.BORDERLESS,
        },
      },
      {
        path: "tables/print/:tableId",
        name: routeNames["print-table"],
        component: TablesPrintView,
        meta: {
          windowMode: WindowMode.BORDERLESS,
        },
      },
      {
        path: "statistics",
        name: routeNames.statistics,
        component: StatisticsView,
      },
      {
        path: "settings",
        component: SettingsView,
        name: routeNames.settings,
        redirect: {
          name: routeNames["settings-general"],
        },
        children: [
          {
            path: "general",
            name: routeNames["settings-general"],
            component: GeneralSettingsView,
          },
          {
            path: "order",
            name: routeNames["settings-order"],
            component: OrderSettingsView,
          },
          {
            path: "account",
            name: routeNames["settings-account"],
            component: AccountSettingsView,
          },
          {
            path: "hosts",
            name: routeNames["settings-hosts"],
            component: HostsSettingsView,
          },
        ],
      },
      {
        path: "*",
        redirect: {
          name: routeNames.admin,
        },
      },
    ],
  },
  // Ordering
  {
    path: "/order",
    name: routeNames.order,
    component: OrderView,
    redirect: {
      name: routeNames["order-main"],
    },
    children: [
      {
        path: "/",
        name: routeNames["order-main"],
        component: OrderMainView,
      },
      ...Object.values(MenuContentType).map((type: string): RouteConfig => {
        return {
          path: "menus/" + type,
          name: routeNames["order-menu-type"] + type,
          props: { selectedMenuType: type },
          meta: { selectedMenuType: type },
          component: OrderMenusView,
        };
      }),
      {
        path: "menus/:menuId",
        name: routeNames["order-menu-items"],
        component: OrderMenuItemsView,
      },
    ],
  },
  {
    path: "/order/menus",
    redirect: { name: routeNames.order },
  },
  {
    path: "/order/:tableId",
    name: routeNames["order-table"],
    component: OrderView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
