/**
 * @copyright 2024 Servely UG (haftungsbeschränkt)
 * @copyright 2021 - 2024 Crystal Creations GbR and Johannes Huther
 */

import {
  createClient,
  PostgrestError,
  SupabaseClient,
} from "@supabase/supabase-js";
import { HttpStatusCode } from "./http-status-codes";

/**
 * The URL and KEY to access supabase or a function to asynchronously retrieve these values.
 */
let supabaseAccess:
  | { url: string; key: string }
  | (() => Promise<{
      url: string;
      key: string;
    }>);

/**
 * A function to set {@link supabaseAccess}.
 *
 * @param access the URL and KEY to access supabase or a function to asynchronously retrieve these values.
 */
export function setSupabaseAccess(
  access:
    | { url: string; key: string }
    | (() => Promise<{
        url: string;
        key: string;
      }>)
): void {
  supabaseAccess = access;
}

/**
 * The supabase client for this application.
 */
let supabase: SupabaseClient;

/**
 * Returns the current supabase client.
 *
 * @return {SupabaseClient} the Supabase-Client
 * @throws {Error} if one of the environment variables `VUE_APP_SUPABASE_URL` and `VUE_APP_SUPABASE_KEY` is not set.
 */
async function getSupabaseClient(): Promise<SupabaseClient> {
  if (!supabase) {
    const { url: supabaseUrl, key: supabaseKey } =
      typeof supabaseAccess === "function"
        ? await supabaseAccess()
        : supabaseAccess;
    supabase = createClient(supabaseUrl, supabaseKey);
    await new Promise<void>((resolve) => {
      const { data: subscription } = supabase.auth.onAuthStateChange(
        (event) => {
          if (event === "SIGNED_IN") {
            subscription?.unsubscribe();
            resolve();
          }
        }
      );
      setTimeout(() => {
        subscription?.unsubscribe();
        resolve();
      }, 500);
    });
  }
  return supabase;
}

export default getSupabaseClient;

/**
 * An {@link Error} thrown, if a supabase database request failed.
 */
export class SupabaseDatabaseError extends Error {
  /**
   * A more detailed message for this {@link SupabaseDatabaseError}.
   */
  details = "";

  /**
   * An optional error code.
   *
   * With some errors, supabase returns an additional error-code, if the error was thrown by the PostgreSQL-database itself.
   * See {@link PostgreSQLErrorCode} for a selection of the most common codes used.
   * Under certain circumstances, a different, not yet defined code is used here
   */
  errorCode?: PostgreSQLErrorCode | string;

  /**
   * The http-status-code of the request.
   *
   * See {@link HttpStatusCode} for a selection of the most common codes used.
   * Under certain circumstances, a different, not yet defined code is used here
   */
  status?: HttpStatusCode | number;

  /**
   * Creates an instance of {@link SupabaseDatabaseError} based on given data.
   *
   * @param props.error The {@link PostgrestError} given by supabase. Contains error message, details and codes.
   * @param props.status The {@link HttpStatusCode} of the request.
   */
  constructor(props: { error: Partial<PostgrestError>; status?: number }) {
    super(props.error.message ?? "SupabaseDatabaseError");

    if (props.error.details) this.details = props.error.details;
    if (props.error.code) this.errorCode = props.error.code;
    if (props.status) this.status = props.status;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, SupabaseDatabaseError.prototype);
  }
}

/**
 * Some of the most common error-codes thrown by the PostgreSQL-database in supabase.
 *
 * @see https://www.postgresql.org/docs/current/errcodes-appendix.html for available codes
 */
export enum PostgreSQLErrorCode {
  /**
   * This error code is also given, if an invalid uuid is used.
   */
  INVALID_TEXT_REPRESENTATION = "22P02",

  /**
   * Error that is thrown, if an executed query contains 0 or more than 1 row when only 1 row was requested.
   *
   * That means that this error is also thrown, if a select-request doesn't return any data for the set filters.
   */
  INVALID_ROWS_AMOUNT = "PGRST116",
}
