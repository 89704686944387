/**
 * @copyright 2024 Servely UG (haftungsbeschränkt)
 * @copyright 2021 - 2024 Crystal Creations GbR and Johannes Huther
 */

/**
 * Available window modes for the {@link AdminView}.
 *
 * Can be used in the meta of a route like "windowMode: WindowMode.xxx".
 */
export enum WindowMode {
  /**
   * The default mode, adds the side-nav and an appbar.
   */
  DEFAULT,

  /**
   * The borderless mode, adds nothing but the `router-view` itself.
   */
  BORDERLESS,

  /**
   * The no-navbar mode, adds only the appbar-container without any content.
   */
  NO_NAVBAR,
}
