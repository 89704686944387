/**
 * Fetches the supabase access variables from the backend.
 *
 * @return a promise of the supabase access variables.
 */
export default async function getSupabaseAccess(): Promise<{
  url: string;
  key: string;
}> {
  const response = await fetch("/api/supabase_access");
  const { url: supabaseUrl, key: supabaseKey, error } = await response.json();

  if (error || response.status === 500 || !supabaseUrl || !supabaseKey) {
    throw new Error(
      "Supabase " +
        (error.errorCode.split("_")[1] || "environment variables") +
        " could not be retrieved from the backend! Please define it using the environment variable `SUPABASE_" +
        (error.errorCode.split("_")[1] || "URL` and `SUPABASE_KEY") +
        "`."
    );
  }

  return { url: supabaseUrl, key: supabaseKey };
}
