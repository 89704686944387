/**
 * @copyright 2024 Servely UG (haftungsbeschränkt)
 * @copyright 2021 - 2024 Crystal Creations GbR and Johannes Huther
 */

import Host from "@common/components/authenticated/host";

/**
 * Available application modes for the views.
 */
export type ApplicationMode =
  /**
   * The default mode, all navbar items are available to administrate everything.
   */
  | "ADMIN"

  /**
   * Only views for the staff is available like staff ordering or the monitor.
   */
  | "STAFF";

/**
 * Tries to switch to admin mode by checking the given pin.
 *
 * If the pin is correct, the mode will be changed to ADMIN and the pin will be cached.
 *
 * @param pin the pin to check
 * @param host the {@link Host} to get the admin pin from
 *
 * @return boolean whether the pin was correct or not
 */
export function switchToAdminMode(pin: string, host: Host): boolean {
  // Check admin pin
  if (host.admin_pin === pin) {
    setApplicationMode("ADMIN");
    setCachedPIN(pin);
    return true;
  } else {
    setApplicationMode("STAFF");
    setCachedPIN();
    return false;
  }
}

/**
 * Switches the application mode to staff and removes the admin pin from storage.
 */
export function switchToStaffMode() {
  setApplicationMode("STAFF");
  setCachedPIN();
}

/**
 * Sets the given {@link ApplicationMode}.
 *
 * @param mode
 */
export function setApplicationMode(mode: ApplicationMode): void {
  localStorage.setItem("application-mode", mode);
}

/**
 * Gets the currently set {@link ApplicationMode}.
 */
export function getApplicationMode(): ApplicationMode {
  return <ApplicationMode>localStorage.getItem("application-mode") ?? "ADMIN";
}

/**
 * Sets the given {@link pin}. If the pin is empty, the item will be removed from localStorage.
 *
 * @param pin
 */
export function setCachedPIN(pin?: string) {
  if (pin) localStorage.setItem("admin-pin", pin);
  else localStorage.removeItem("admin-pin");
}

/**
 * Returns the cached PIN, if available.
 */
export function getCachedPIN(): string {
  return localStorage.getItem("admin-pin") ?? "";
}
