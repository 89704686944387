/**
 * @copyright 2024 Servely UG (haftungsbeschränkt)
 * @copyright 2021 - 2024 Crystal Creations GbR and Johannes Huther
 */

import getSupabaseClient, { PostgreSQLErrorCode } from "@common/supabase";
import { Session, User as SupabaseUser } from "@supabase/supabase-js";
import { User } from "@common/components/user";
import { setApplicationMode, switchToStaffMode } from "@/lib/application-mode";

/**
 * Creates an user in supabase based on an email address and password and sends a verification mail to provided email address.
 *
 * @param email the email address of the user.
 * @param password the password of the user.
 * @return the user.
 * @throws error if the sign up fails or the returned user is null.
 */
export async function signUp(
  email: string,
  password: string
): Promise<SupabaseUser> {
  const { user, error } = await (
    await getSupabaseClient()
  ).auth.signUp(
    {
      email: email,
      password: password,
    },
    { redirectTo: window.location.origin + "/admin" }
  );
  if (error) throw error;
  if (!user) throw new Error("User data is null");
  return user;
}

/**
 * Signs in an existing user in supabase based on an email address and password and returns the user as well as the session.
 *
 * @param email the email address of the user.
 * @param password the password of the user.
 * @return the user and session.
 * @throws error if the sign in fails or the returned user/ session is null.
 */
export async function signIn(
  email: string,
  password: string
): Promise<{ user: SupabaseUser; session: Session }> {
  const { user, session, error } = await (
    await getSupabaseClient()
  ).auth.signIn({
    email: email,
    password: password,
  });
  if (error) throw error;
  if (!user) throw new Error("User data is null");
  if (!session) throw new Error("Session data is null");

  // Switch to admin mode, if user is admin
  const supabaseUser = await getUser();
  if (supabaseUser.role === "admin") setApplicationMode("ADMIN");

  return { user: user, session: session };
}

/**
 * Signs out the current user.
 *
 * @throws error if the sign-out fails.
 */
export async function signOut() {
  const { error } = await (await getSupabaseClient()).auth.signOut();
  if (error) throw error;

  switchToStaffMode();

  return;
}

/**
 * Returns the current user.
 *
 * @return the current user.
 * @throws error if user data is null.
 */
export async function getUser(): Promise<
  {
    supabaseUser: SupabaseUser;
  } & User
> {
  const supabase = await getSupabaseClient();
  const user = supabase.auth.user();

  if (!user)
    throw new Error(
      "Could not get authorized user from supabase client. Data of `user` is null."
    );

  // Load user role and user settings
  const { data, error } = await supabase
    .from<User>("users")
    .select("id, maximum_hosts, role")
    .eq("id", user.id)
    .single();

  if (error) {
    // Check, if the error is, because the user does not exist yet, then we initialize an empty, default user
    if (error.code === PostgreSQLErrorCode.INVALID_ROWS_AMOUNT) {
      return {
        supabaseUser: user,
        id: user.id,
        role: "user",
        maximum_hosts: 1,
      };
    } else {
      throw error;
    }
  }
  if (!data) throw new Error("Users data is null.");

  return {
    supabaseUser: user,
    ...data,
  };
}

/**
 * Returns the current session.
 *
 * @return the current session.
 * @throws error if session data is null.
 */
export async function getSession(): Promise<Session> {
  const session = (await getSupabaseClient()).auth.session();
  if (!session)
    throw new Error(
      "Could not get session of authorized user from supabase client."
    );
  return session;
}

/**
 * Resets the password for an email address.
 *
 * @param email the email address of the user.
 * @throws error if password reset fails.
 */
export async function resetPassword(email: string): Promise<void> {
  const { error } = await (
    await getSupabaseClient()
  ).auth.api.resetPasswordForEmail(email, {
    redirectTo: window.location.origin,
  });
  if (error) throw error;
  return;
}

/**
 * An RFC 5322 compliant regex used to validate an email address.
 *
 * Sourced from https://stackoverflow.com/a/201378/10816438.
 */
const mailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

/**
 * A function that returns whether a string is a valid email address.
 *
 * @param email the email address.
 * @return whether a string is a valid email address.
 */
export function isValidEmail(email: string): boolean {
  return mailRegex.test(email);
}

/**
 * The special characters allowed in a password.
 */
export const specialChars = "@$!%*\\?&\\#~_°^=+-";

/**
 * A regex used to validate a password.
 */
const passwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[" +
    specialChars +
    "])[A-Za-z\\d" +
    specialChars +
    "]{8,}$"
);

/**
 * A regex to check if all chars of a password are valid.
 */
const passwordValidChars = new RegExp("^[A-Za-z\\d" + specialChars + "]*$");

/**
 * A function that returns whether a string is a valid password.
 *
 * @param password the password address.
 * @return whether the string is a valid password or the reference to the relevant error message.
 */
export function isValidPassword(
  password: string
):
  | true
  | "admin.auth.common.password_complexity"
  | "admin.auth.common.password_charset" {
  if (passwordRegex.test(password)) return true;

  return passwordValidChars.test(password)
    ? "admin.auth.common.password_complexity"
    : "admin.auth.common.password_charset";
}
