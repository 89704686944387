import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as socketio from "socket.io-client";
import VueSocketIOExt from "vue-socket.io-extended";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import i18n from "./i18n";
import { AnimatedCheckmark } from "@crystal-creations/animated-checkmark";
import "@fontsource/roboto";
import AOS from "aos";
import "aos/dist/aos.css";

// Set supabase access variable retriever.
import { setSupabaseAccess } from "@common/supabase";
import getSupabaseAccess from "@/lib/supabase-access";
setSupabaseAccess(getSupabaseAccess);

Vue.config.productionTip = false;

Vue.use(VueMeta);

const socketIO = socketio.io();

Vue.use(VueSocketIOExt, socketIO);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.component("AnimatedCheckmark", AnimatedCheckmark);

AOS.init({
  // Only animate once, if the user scrolls to the top again, the content should not disappear
  once: true,
});

export { socketIO };
