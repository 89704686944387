
import { Component, Vue } from "vue-property-decorator";

/**
 * The main {@link Vue}-View that contains the App.
 */
@Component({
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title: "Servely",
      titleTemplate: "%s | Servely",
    };
  },
})
export default class App extends Vue {}
