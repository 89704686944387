/**
 * @copyright 2024 Servely UG (haftungsbeschränkt)
 * @copyright 2021 - 2024 Crystal Creations GbR and Johannes Huther
 */

import Item, { ItemType } from "./item";

/**
 * The type of the contents of a {@link Menu}. Used to differentiate between {@link Menu}s for drinks and for food.
 */
export enum MenuContentType {
  DRINKS = "drinks",
  FOOD = "food",
  DEPOSIT = "deposit",
  NONE = "none",
}

/**
 * Class, that contains information on a menu.
 */
export default abstract class Menu {
  /**
   * The unique id of this {@link Menu}.
   */
  id?: string;

  /**
   * The name of this {@link Menu}.
   */
  name?: string;

  /**
   * The description of this {@link Menu}.
   */
  description?: string;

  /**
   * The {@link MenuContentType} of this {@link Menu}.
   */
  type: MenuContentType;

  /**
   * Whether this {@link Menu} is activated or not.
   */
  active = true;

  /**
   * The sort index to order by.
   */
  index = 0;

  /**
   * The list of {@link MenuItem}s in this {@link Menu}.
   */
  protected _items?: MenuItem[];

  /**
   * Creates an instance of {@link Menu} based on given data.
   *
   * @param data The data to create the {@link Menu} from.
   */
  protected constructor(data: MenuType) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
    if (data.active !== undefined) this.active = data.active;
    this.index = data.index ?? 0;
    if (data.items !== undefined) {
      this._items = [];
      for (const item of data.items) {
        this._items.push(this.getMenuItemFromType(item));
      }
    }
  }

  /**
   * Returns an instance of the local implementation of {@link MenuItem} for the menuItem data.
   *
   * @param menuItem the menuItem data
   * @return an instance of the local implementation of {@link MenuItem} for the menuItem data.
   */
  abstract getMenuItemFromType(menuItem: MenuItemTypeWithItem): MenuItem;

  /**
   * Returns this {@link Menu} in its serialized {@link MenuType} form.
   *
   * @param id whether the id should be contained
   * @param children whether the items should be contained
   * @return the serialized {@link Menu}.
   */
  toType(id = true, children = false): MenuType {
    return {
      id: id ? this.id : undefined,
      name: this.name,
      description: this.description,
      type: this.type,
      active: this.active,
      index: this.index,
      items: children
        ? this._items?.map((item) => {
            return item.toType();
          })
        : undefined,
    };
  }

  /**
   * Returns the {@link Item}s this {@link Menu} has.
   *
   * If the {@link Item}s are not yet loaded into {@link #_items} they are queried from the database. Otherwise, {@link #_items} is returned.
   *
   * @param {boolean} force if true, the {@link Item}s will be forced to load from database.
   *
   * @return the {@link Item}s
   * @throws Error if the entries cannot be queried from the database or the received data is null.
   */
  async getItems(force = false): Promise<MenuItem[]> {
    if (!this._items || force) {
      this._items = await this._queryItems();
    }
    return this._items;
  }

  getItemsSync(): MenuItem[] | null {
    if (!this._items) return null;

    return this._items;
  }

  /**
   * Queries the {@link Item}s this {@link Menu} has from the database.
   *
   * @return the {@link Item}s
   * @throws Error if the entries cannot be queried from the database or the received data is null.
   * @protected
   */
  protected abstract _queryItems(): Promise<MenuItem[]>;
}

/**
 * A type that contains the properties for a menu.
 */
export type MenuType = {
  /**
   * The unique id of this {@link MenuType}.
   */
  id?: string;

  /**
   * The unique id of the {@link Host} this {@link MenuType} belongs to.
   */
  hostId?: string;

  /**
   * The name of this {@link MenuType}.
   */
  name?: string;

  /**
   * The description of this {@link MenuType}.
   */
  description?: string;

  /**
   * The {@link MenuContentType} of this {@link MenuType}.
   */
  type: MenuContentType;

  /**
   * Whether this {@link MenuType} is activated or not.
   */
  active?: boolean;

  /**
   * The sort index to order by.
   */
  index?: number;

  /**
   * The list of {@link MenuItemType}s in this {@link MenuType}.
   */
  items?: MenuItemTypeWithItem[];
};

/**
 * Class, that contains information on an {@link Item} in the {@link Menu}.
 */
export abstract class MenuItem {
  /**
   * The {@link Item} that is included.
   */
  item: Item;

  /**
   * The sort index to order the {@link Item}s by.
   */
  index = 0;

  /**
   * Creates an instance of {@link MenuItem} based on given data.
   *
   * @param data the data to create the {@link MenuItem} from.
   */
  protected constructor(data: MenuItemTypeWithItem) {
    this.item = this.getItemFromType(data.item);
    this.index = data.index ?? 0;
  }

  /**
   * Returns an instance of the local implementation of {@link Item} for the item data.
   *
   * @param item the item data
   * @return an instance of the local implementation of {@link Item} for the item data.
   */
  abstract getItemFromType(item: ItemType): Item;

  /**
   * Returns this {@link MenuItem} in its serialized {@link MenuItemType} form.
   *
   * @return the serialized {@link MenuItem}.
   */
  toType(): MenuItemTypeWithItem {
    return {
      item: this.item.toType(),
      index: this.index,
    };
  }
}

/**
 * A type that contains the properties for on item in a menu.
 */
export type MenuItemType = {
  /**
   * The unique id of the {@link Menu} this {@link MenuItemType} belongs to.
   */
  menuId?: string;

  /**
   * The unique id of the {@link Item} this {@link MenuItemType} references.
   */
  itemId?: string;

  /**
   * The sort index to order the {@link Item}s by.
   */
  index?: number;
};

/**
 * A type that contains the properties for an item in a menu with the {@link ItemType} itself.
 */
export type MenuItemTypeWithItem = MenuItemType & {
  /**
   * The {@link ItemType} this {@link MenuItemType} references.
   */
  item: ItemType;
};
